$pattern: "/images/pattern.png";

$brand-color: #90E39A;
$html-background-color: #f1f1f1;
$secondary-brand-color: #3C887E;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";

$section-rotation: 5deg;

@import "elements";
@import "layout";
@import "blog";
@import "forms";
@import "navigation";
@import "footer";
